import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={onClose} // Arka plana tıklanınca kapatma
        >
            <div
                className="bg-gray-800 rounded-lg shadow-lg w-full max-w-lg p-6 relative"
                onClick={(e) => e.stopPropagation()} // İçeriğe tıklamayı durdur
            >
                {/* Modal İçerik */}
                {children}

                {/* Kapatma Butonu */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-200 text-2xl font-bold"
                    aria-label="Kapat"
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default Modal;
