import { createContext, useState, useContext } from "react";

// Auth Context oluşturma
const AuthContext = createContext();

// Context sağlayıcı bileşeni
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Kullanıcı bilgilerini saklamak için state

  const login = (userData) => {
    setUser(userData); // Kullanıcı bilgilerini güncelle
    localStorage.setItem("token", userData.token); // Token'ı localStorage'a kaydet
    localStorage.setItem("ad", userData.ad); // Kullanıcı adını kaydet
    localStorage.setItem("profileImage", userData.profileImage); // Profil resmini kaydet
  };

  const logout = () => {
    setUser(null); // Kullanıcı bilgisini temizle
    localStorage.removeItem("token"); // Token'ı sil
    localStorage.removeItem("ad"); // Kullanıcı adını sil
    localStorage.removeItem("profileImage"); // Profil resmini sil
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Kullanım için bir hook
export const useAuth = () => useContext(AuthContext);
