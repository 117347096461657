import React, { useEffect, useState } from "react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/blogs`);
                setBlogs(response.data);
            } catch (err) {
                setError("Blogları alırken bir hata oluştu.");
            }
        };
        fetchBlogs();
    }, []);

    return (
        <div className="container mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6 text-center text-white">Bloglar</h1>
            {error && <p className="text-red-500 text-center">{error}</p>}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {blogs.map((blog) => (
                    <div key={blog.id} className="bg-gray-800 p-6 rounded shadow">
                        <h2 className="text-xl font-bold text-white">{blog.title}</h2>
                        <p className="text-gray-300 mt-2">{blog.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
