import React, { useState } from "react";
import ResetPasswordModal from "./ResetPasswordModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const LoginForm = ({ onClose }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isResetModalOpen, setResetModalOpen] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setMessage("Lütfen tüm alanları doldurun.");
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
                email,
                password,
            });

            console.log("API Yanıtı:", response.data); // Yanıtı kontrol edin
            const { token, user } = response.data; // API yanıtında `user` bilgisi olmalı

            if (token) {
                // Token'ı ve kullanıcı bilgilerini kaydet
                localStorage.setItem("token", token);
                localStorage.setItem("ad", user?.ad || "Kullanıcı"); // Kullanıcı adı
                localStorage.setItem("soyad", user?.soyad || ""); // Kullanıcı soyadı
                localStorage.setItem("email", user?.email || ""); // Kullanıcı e-postası
                localStorage.setItem(
                    "profileImage",
                    user?.profileImage || "https://via.placeholder.com/40"
                ); // Profil resmi

                setMessage("Giriş başarılı!");
                console.log("API Yanıtı:", response.data);

                setTimeout(() => {
                    if (onClose) onClose(); // Modal'ı kapat
                    navigate("/profile"); // Profil sayfasına yönlendir
                }, 1000);
            } else {
                setMessage("Token alınamadı. Lütfen tekrar deneyin.");
            }
        } catch (error) {
            console.error("Giriş hatası:", error.response?.data || error.message);
            setMessage(
                error.response?.data?.message || "Giriş başarısız! Lütfen bilgilerinizi kontrol edin."
            );
            setTimeout(() => setMessage(""), 3000); // Hata mesajını temizle
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit}
                className="space-y-4 bg-gray-800 p-6 rounded shadow-md"
            >
                <h2 className="text-2xl font-bold text-center text-blue-400 mb-4">
                    Giriş Yap
                </h2>
                <input
                    type="email"
                    placeholder="E-posta Adresi"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-600 p-2 w-full rounded bg-[#1a202c] text-white focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
                <input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border border-gray-600 p-2 w-full rounded bg-[#1a202c] text-white focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
                <div className="flex justify-between items-center">
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition duration-300"
                    >
                        Giriş Yap
                    </button>
                    <button
                        type="button"
                        onClick={() => setResetModalOpen(true)}
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition duration-300"
                    >
                        Şifrenizi mi unuttunuz?
                    </button>
                </div>
                {message && (
                    <p
                        className={`mt-4 text-center ${
                            message === "Giriş başarılı!" ? "text-green-500" : "text-red-500"
                        }`}
                    >
                        {message}
                    </p>
                )}
            </form>

            {/* Şifre Sıfırlama Modal */}
            {isResetModalOpen && (
                <ResetPasswordModal
                    isOpen={isResetModalOpen}
                    onClose={() => setResetModalOpen(false)}
                />
            )}
        </>
    );
};

export default LoginForm;
