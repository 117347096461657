import React, { useState } from "react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const ResetPasswordModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false); // Hata durumunu takip etmek için

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${API_BASE_URL}/api/auth/reset-password-request`, {
                email,
            });
            setMessage("Şifre sıfırlama e-postası gönderildi!");
            setIsError(false); // Başarılı durumda hata olmadığını belirtiyoruz

            // 2 saniye sonra modalı kapat
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setMessage("Hata oluştu. Tekrar deneyin.");
            setIsError(true); // Hata durumunu true yapıyoruz
            console.error("Şifre sıfırlama hatası:", error.response?.data || error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-gray-800 p-6 rounded shadow-md w-full max-w-sm">
                <h2 className="text-xl font-bold text-white mb-4">Şifre Sıfırla</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="E-posta Adresi"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border p-2 w-full rounded bg-gray-700 text-white mb-4"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded w-full"
                    >
                        Sıfırla
                    </button>
                </form>
                {message && (
                    <p
                        className={`mt-4 text-center ${
                            isError ? "text-red-500" : "text-green-500"
                        }`}
                    >
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default ResetPasswordModal;
