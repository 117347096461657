import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const UpdatePassword = () => {
    const { token } = useParams(); // URL'den token alınıyor
    const navigate = useNavigate(); // Sayfa yönlendirme için
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Şifre güncelleme isteği başlatıldı.");
        console.log("Token:", token);
        console.log("Yeni Şifre:", newPassword);

        // Şifre boş mu kontrol et
        if (!newPassword.trim()) {
            console.warn("Yeni şifre alanı boş.");
            setMessage("Lütfen yeni şifrenizi girin.");
            setIsError(true);
            return;
        }

        try {
            console.log("API'ye istek gönderiliyor...");
            
            const response = await axios.put(
                `${API_BASE_URL}/api/auth/update-password`,
                { token, newPassword }, // Body'ye token ve şifre gönderiliyor
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("API yanıtı alındı:", response.data);

            // Başarılı yanıt durumunda mesajı göster
            setMessage(response.data.message || "Şifre başarıyla güncellendi!");
            setIsError(false);
            setNewPassword(""); // Formu temizle
        } catch (error) {
            // Hata durumunda hata mesajını işleme
            const errorMessage =
                error.response?.data?.message || "Sunucuyla bağlantı kurulurken bir hata oluştu.";
            console.error("Şifre güncelleme hatası:", error.response || error.message);
            setMessage(errorMessage);
            setIsError(true);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-900">
            <div className="bg-gray-800 p-6 rounded shadow-md w-full max-w-sm">
                <h2 className="text-xl font-bold text-white mb-4">Yeni Şifre Belirle</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        placeholder="Yeni Şifre"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="border p-2 w-full rounded bg-gray-700 text-white mb-4"
                        required
                    />
                    <button
                        type="submit"
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded w-full"
                    >
                        Güncelle
                    </button>
                </form>
                {message && (
                    <p
                        className={`mt-4 text-center ${
                            isError ? "text-red-500" : "text-green-500"
                        }`}
                    >
                        {message}
                    </p>
                )}
                {/* Şifre güncelleme başarılıysa giriş yapma butonunu göster */}
                {!isError && message.includes("başarıyla") && (
                    <button
                        onClick={() => {
                            console.log("Giriş yap sayfasına yönlendiriliyor...");
                            navigate("/login");
                        }}
                        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded w-full"
                    >
                        Giriş Yap
                    </button>
                )}
            </div>
        </div>
    );
};

export default UpdatePassword;
