import React, { useState } from "react";
import axios from "axios";
import "./dropdown.css"; // Güncel CSS dosyası dahil

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const RegisterForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        username: "",
        ad: "",
        soyad: "",
        email: "",
        password: "",
    });
    const [message, setMessage] = useState({ text: "", type: "" });
    const [loading, setLoading] = useState(false); // Yükleme durumu
    const [registrationSuccess, setRegistrationSuccess] = useState(false); // Başarı durumu

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateUsername = (username) => {
        const regex = /^[a-zA-Z0-9]+$/; // Türkçe karakter içermeyen birleşik kullanıcı adı
        return regex.test(username);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Kullanıcı adı doğrulaması
        if (!validateUsername(formData.username)) {
            setMessage({
                text: "Kullanıcı adı Türkçe karakter içermemeli ve birleşik olmalıdır.",
                type: "error",
            });
            return;
        }

        // Kayıt işlemi
        try {
            setLoading(true);
            const response = await axios.post(`${API_BASE_URL}/api/auth/register`, formData);
            setMessage({
                text: response.data.message || "Kayıt başarılı!",
                type: "success", // Mesaj türü başarı
            });

            setFormData({
                username: "",
                ad: "",
                soyad: "",
                email: "",
                password: "",
            }); // Formu sıfırla

            // Kayıt başarılı -> Mesaj göster ve modal'ı kapat
            setRegistrationSuccess(true); // Başarı durumunu aktif et
            setTimeout(() => {
                if (onClose) onClose(); // Modal'ı kapat
            }, 2000); // 2 saniye sonra modal kapanır
        } catch (err) {
            setMessage({
                text:
                    err.response?.data?.message ||
                    "Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.",
                type: "error", // Mesaj türü hata
            });
        } finally {
            setLoading(false);
        }
    };

    if (registrationSuccess) {
        return (
            <div className="bg-gray-800 p-6 rounded shadow-md text-center">
                <h2 className="text-2xl font-bold text-blue-400 mb-4">Kayıt Başarılı!</h2>
                <p className="text-green-500">Giriş yapabilirsiniz.</p>
            </div>
        );
    }

    return (
        <form
            onSubmit={handleSubmit}
            className="space-y-4 bg-gray-800 p-6 rounded shadow-md"
        >
            <h2 className="text-2xl font-bold text-center text-blue-400 mb-4">
                Kayıt Ol
            </h2>

            {message.text && (
                <div
                    className={`text-center mb-4 ${
                        message.type === "success" ? "text-green-500" : "text-red-500"
                    }`}
                >
                    {message.text}
                </div>
            )}

            {/* Kullanıcı Adı */}
            <div>
                <label className="block mb-2 text-gray-400">Kullanıcı Adı</label>
                <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    placeholder="Kullanıcı adınız"
                    className="border border-gray-700 w-full p-2 rounded bg-gray-700 text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
            </div>

            {/* Ad */}
            <div>
                <label className="block mb-2 text-gray-400">Ad</label>
                <input
                    type="text"
                    name="ad"
                    value={formData.ad}
                    onChange={handleInputChange}
                    placeholder="Adınız"
                    className="border border-gray-700 w-full p-2 rounded bg-gray-700 text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
            </div>

            {/* Soyad */}
            <div>
                <label className="block mb-2 text-gray-400">Soyad</label>
                <input
                    type="text"
                    name="soyad"
                    value={formData.soyad}
                    onChange={handleInputChange}
                    placeholder="Soyadınız"
                    className="border border-gray-700 w-full p-2 rounded bg-gray-700 text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
            </div>

            {/* E-posta */}
            <div>
                <label className="block mb-2 text-gray-400">E-posta</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="E-posta adresiniz"
                    className="border border-gray-700 w-full p-2 rounded bg-gray-700 text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
            </div>

            {/* Şifre */}
            <div>
                <label className="block mb-2 text-gray-400">Şifre</label>
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Şifreniz"
                    className="border border-gray-700 w-full p-2 rounded bg-gray-700 text-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                    required
                />
            </div>

            {/* Kayıt Butonu */}
            <button
                type="submit"
                disabled={loading}
                className={`${
                    loading
                        ? "bg-blue-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                } text-white py-2 px-4 rounded w-full mt-4`}
            >
                {loading ? "Kayıt Oluyor..." : "Kayıt Ol"}
            </button>
        </form>
    );
};

export default RegisterForm;
