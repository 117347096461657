import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const Profile = () => {
  const [userData, setUserData] = useState({
    ad: "",
    soyad: "",
    username: "",
    email: "",
    role: "",
    bio: "",
    socialLinks: {},
  });
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
		`${API_BASE_URL}/api/auth/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setUserData({
          ...data,
          socialLinks: data.socialLinks || {},
        });
        if (data.profileImage) {
          setPreviewImage(`${API_BASE_URL}${data.profileImage}`);
        }
      } catch (err) {
        console.error("Profil bilgileri alınamadı:", err);
        setMessage("Profil bilgileri alınırken bir hata oluştu.");
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setMessage("Lütfen geçerli bir resim dosyası seçin.");
      return;
    }
    setProfileImage(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleSendResetLink = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/send-reset-link`,
        { email: userData.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message || "Şifre sıfırlama linki e-postanıza gönderildi.");
    } catch (err) {
      console.error("Şifre sıfırlama linki gönderilemedi:", err.response?.data || err.message);
      setMessage("Şifre sıfırlama linki gönderilirken bir hata oluştu.");
    }
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  const token = localStorage.getItem("token");

  const formData = new FormData();
  if (userData.ad) formData.append("ad", userData.ad);
  if (userData.soyad) formData.append("soyad", userData.soyad);
  if (userData.username) formData.append("username", userData.username);
  if (userData.email) formData.append("email", userData.email);
  if (userData.bio) formData.append("bio", userData.bio);
  if (userData.role) formData.append("role", userData.role);
  if (Object.keys(userData.socialLinks).length > 0)
    formData.append("socialLinks", JSON.stringify(userData.socialLinks));
  if (profileImage) formData.append("profileImage", profileImage);

  // Burada gönderilen veriyi konsola yazdırıyoruz
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }

  try {
    const response = await axios.put(`${API_BASE_URL}/api/auth/profile`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    setMessage(response.data.message || "Profil başarıyla güncellendi.");
    if (response.data.user.profileImage) {
      setPreviewImage(`${API_BASE_URL}${response.data.user.profileImage}`);
    }
  } catch (err) {
    console.error("Profil güncelleme hatası:", err.response?.data || err.message);
    setMessage("Profil güncellenirken1 bir hata oluştu.");
  }
};


  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-200">
      <div className="max-w-6xl mx-auto py-12 px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Yan Menü */}
          <div className="md:col-span-1 bg-gray-800 rounded-lg shadow-md p-6">
            <div className="text-center mb-6">
              <div className="w-32 h-32 mx-auto rounded-full border-4 border-blue-500 overflow-hidden">
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Profil Resmi"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-700 text-gray-400">
                    <span className="text-2xl">+</span>
                  </div>
                )}
              </div>
              <label
                htmlFor="profileImageUpload"
                className="block mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg cursor-pointer hover:bg-blue-700"
              >
                Resim Yükle
              </label>
              <input
                type="file"
                id="profileImageUpload"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>
            <ul className="space-y-4">
              <li>
                <button className="text-blue-400 hover:text-blue-500 w-full text-left">
                  Profilim
                </button>
              </li>
              <li>
                <button className="text-blue-400 hover:text-blue-500 w-full text-left">
                  Favorilerim
                </button>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="text-red-500 hover:text-red-600 w-full text-left"
                >
                  Çıkış Yap
                </button>
              </li>
            </ul>
          </div>

          {/* Profil Formu */}
          <div className="md:col-span-3 bg-gray-800 rounded-lg shadow-md p-6">
            <h1 className="text-2xl font-bold text-center mb-6 text-blue-400">
              Profil Yönetimi
            </h1>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm mb-2 text-gray-400">Ad</label>
                  <input
                    type="text"
                    name="ad"
                    value={userData.ad}
                    onChange={handleInputChange}
                    className="w-full p-2 bg-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-2 text-gray-400">Soyad</label>
                  <input
                    type="text"
                    name="soyad"
                    value={userData.soyad}
                    onChange={handleInputChange}
                    className="w-full p-2 bg-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-2 text-gray-400">Kullanıcı Adı</label>
                  <input
                    type="text"
                    name="username"
                    value={userData.username}
                    onChange={handleInputChange}
                    className="w-full p-2 bg-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-2 text-gray-400">E-posta</label>
                  <input
                    type="email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 bg-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring focus:ring-blue-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm mb-2 text-gray-400">Biyografi</label>
                <textarea
                  name="bio"
                  value={userData.bio}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring focus:ring-blue-500"
                  rows="4"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Değişiklikleri Kaydet
              </button>
            </form>

            <div className="mt-8">
              <h2 className="text-lg font-bold text-blue-400 mb-4">Şifre Sıfırlama</h2>
              <button
                type="button"
                onClick={handleSendResetLink}
                className="w-full py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
              >
                Şifre Sıfırlama Linki Gönder
              </button>
            </div>
          </div>
        </div>
        {message && (
          <div className="mt-6 text-center">
            <span className="text-sm bg-gray-800 text-blue-400 py-2 px-4 rounded-lg inline-block">
              {message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
