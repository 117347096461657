import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dropdown.css"; // Dropdown için CSS dosyası
import ImageSlider from "./ImageSlider";
import Modal from "./Modal"; // Modal bileşeni
import LoginRegisterTabs from "./LoginRegisterTabs"; // Giriş / Kayıt bileşeni

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Kullanıcı giriş durumu
  const [isDropdownOpen, setDropdownOpen] = useState(false); // Dropdown durumu
  const [isModalOpen, setModalOpen] = useState(false); // Modal durumu
  const [user, setUser] = useState({
    ad: "Misafir",
    profileImage: "https://via.placeholder.com/40",
  }); // Kullanıcı bilgisi

  // Kullanıcı oturum bilgilerini kontrol et
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await axios.get(
		  `${API_BASE_URL}/api/auth/profile`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = response.data;
          setIsLoggedIn(true);
          setUser({
            ad: data.ad || "Misafir",
            profileImage: `${API_BASE_URL}${data.profileImage || "/default.png"}`,
          });
        } else {
          setIsLoggedIn(false);
          setUser({
            ad: "Misafir",
            profileImage: "https://via.placeholder.com/40",
          });
        }
      } catch (err) {
        console.error("Kullanıcı bilgileri alınamadı:", err);
        setIsLoggedIn(false);
        setUser({
          ad: "Misafir",
          profileImage: "https://via.placeholder.com/40",
        });
      }
    };

    fetchData();
  }, []);

  // Çıkış yapma işlemi
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("ad");
    localStorage.removeItem("profileImage");
    setIsLoggedIn(false);
    setUser({
      ad: "Misafir",
      profileImage: "https://via.placeholder.com/40",
    });
    setDropdownOpen(false);
  };

  // Özellikler listesi
  const features = [
    {
      title: "Sınırsız Güvenlik",
      description: "Sunduğumuz hizmetlerle güvenlik endişelerinize son verin.",
      delay: "0",
    },
    {
      title: "Hız ve Koruma",
      description: "İşlemlerinizi hızlandırırken tam koruma sağlayın.",
      delay: "400",
    },
    {
      title: "Sorunsuz İşlemler",
      description: "Form ve imza işlemlerinde kolaylık ve güvenliği bir arada sunuyoruz.",
      delay: "400",
    },
    {
      title: "Modern Çözümler",
      description: "Güncel ve güvenilir teknolojilerle zaman tasarrufu sağlayın.",
      delay: "400",
    },
    {
      title: "Hızlı Onay",
      description: "Otomatik sistemlerimizle işlemleriniz anında tamamlanır.",
      delay: "400",
    },
    {
      title: "Profesyonel Destek",
      description: "Her adımda güvenli ve ücretsiz çözümlerle yanınızdayız.",
      delay: "400",
    },
  ];

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-gray-800 py-4 shadow-lg sticky top-0 z-10">
        <div className="container mx-auto flex justify-between items-center px-6">
          <h1 className="text-2xl font-bold text-blue-500">ASForce</h1>
          <nav className="hidden md:flex space-x-6">
            <a href="#example" className="hover:text-blue-400">
              Örnek Resim
            </a>
            <a href="#features" className="hover:text-blue-400">
              Özellikler
            </a>
            <a href="#about" className="hover:text-blue-400">
              Hakkında
            </a>
            <a href="#download" className="hover:text-blue-400">
              İndir
            </a>
          </nav>
          <div>
            {isLoggedIn ? (
              <div className="relative flex items-center space-x-4">
                <span className="flex items-center space-x-2 text-sm">
                  <img
                    src={user.profileImage}
                    alt="Profil Resmi"
                    className="w-10 h-10 rounded-full border-2 border-blue-500"
                  />
                  <strong>Hoş geldiniz, {user.ad}!</strong>
                </span>
                <button
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded"
                >
                  Hesabım
                </button>
                {isDropdownOpen && (
                  <div
                    className="dropdown absolute bg-gray-700 text-white py-2 px-4 rounded shadow-md"
                    onBlur={() => setDropdownOpen(false)}
                  >
                    <a href="/profile" className="dropdown-item block py-1 hover:bg-gray-600">
                      Profil
                    </a>
                    <button
                      onClick={handleLogout}
                      className="dropdown-item block py-1 hover:bg-gray-600"
                    >
                      Çıkış Yap
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => setModalOpen(true)}
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded"
              >
                Giriş Yap / Kayıt Ol
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Example Image Section */}
      <section id="example" className="py-16 bg-gray-900 text-center">
        <div className="home-page">
          <h1 className="text-center text-3xl font-bold text-blue-500 mb-6">
            Modern Resim Geçişi
          </h1>
          <ImageSlider />
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-16 bg-gray-900 text-center">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6">Özellikler</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-gray-800 p-6 rounded shadow-md transform transition-transform hover:scale-105"
                data-aos="fade-up"
                data-aos-delay={feature.delay}
              >
                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-16 bg-gray-800 text-center text-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6">Hakkında</h2>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto">
            ASForce, dijital imza işlemlerinde kullanıcılarına profesyonel ve güvenilir bir çözüm
            sunar, form imzalama süreçlerini modern bir yaklaşımla ele alır.
          </p>
        </div>
      </section>

      {/* Download Section */}
      <section
        id="download"
        className="py-16 bg-blue-600 text-center text-white"
      >
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6">Şimdi Başlayın</h2>
          <p className="text-lg mb-6">
            Şimdi indirin ve otomatik form imzalama sürecinin sağladığı hız ve kolaylığın tadını
            çıkarın.
          </p>
          <a
            href="https://www.abdurrahimsenturk.com.tr/update/ASForce.zip"
            className="bg-white text-blue-600 hover:text-blue-700 font-bold py-3 px-6 rounded transition duration-300"
          >
            Ücretsiz İndir
          </a>
        </div>
      </section>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <LoginRegisterTabs />
      </Modal>
    </div>
  );
};

export default Home;
