import React, { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const LoginRegisterTabs = () => {
    const [activeTab, setActiveTab] = useState("login");

    // Sekme değişikliği için bir işlev tanımlıyoruz
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
            {/* Sekme düğmeleri */}
            <div className="flex justify-center mb-6 border-b border-gray-700">
                <button
                    onClick={() => handleTabChange("login")}
                    className={`py-2 px-6 font-semibold transition-colors border-b-2 ${
                        activeTab === "login"
                            ? "border-blue-500 text-blue-500"
                            : "border-transparent text-gray-400 hover:text-white"
                    }`}
                >
                    Giriş Yap
                </button>
                <button
                    onClick={() => handleTabChange("register")}
                    className={`py-2 px-6 font-semibold transition-colors border-b-2 ${
                        activeTab === "register"
                            ? "border-blue-500 text-blue-500"
                            : "border-transparent text-gray-400 hover:text-white"
                    }`}
                >
                    Kayıt Ol
                </button>
            </div>
            {/* İçerik alanı */}
            <div className="bg-gray-900 p-6 rounded-b-lg">
                {activeTab === "login" ? <LoginForm /> : <RegisterForm />}
            </div>
        </div>
    );
};

export default LoginRegisterTabs;
