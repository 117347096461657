import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext"; // AuthProvider ve AuthContext'i ekledik
import Home from "./pages/Home";
import Login from "./pages/LoginForm";
import Register from "./pages/LoginRegisterTabs";
import Profile from "./pages/Profile";
import BlogList from "./pages/BlogList";
import UpdatePassword from "./pages/UpdatePassword";

function App() {
  return (
    <AuthProvider> {/* AuthProvider ile tüm uygulamayı sarmaladık */}
      <Router>
        <div className="bg-gray-900 text-white min-h-screen">
          {/* Header */}
          <Header />
          
          {/* Routes */}
          <main className="container mx-auto p-4">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/reset-password/:token" element={<UpdatePassword />} />
              <Route path="/update-password/:token" element={<UpdatePassword />} />
            </Routes>
          </main>

          {/* Footer */}
          <footer className="bg-gray-800 text-center p-4">
            <p>&copy; 2024 ASForce Tüm hakları saklıdır.</p>
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
}

// Header Bileşeni
const Header = () => {
  const { user, logout } = useAuth(); // AuthContext'ten user ve logout fonksiyonlarını aldık

  return (
    <header className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          <Link to="/" className="hover:text-blue-500">
            ASForce
          </Link>
        </h1>
        <nav>
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="hover:text-blue-500">
                Anasayfa
              </Link>
            </li>
            {user ? ( // Kullanıcı giriş yaptıysa
              <>
                <li>
                  <Link to="/profile" className="hover:text-blue-500">
                    Profil
                  </Link>
                </li>
                <li>
                  <Link to="/blogs" className="hover:text-blue-500">
                    Bloglar
                  </Link>
                </li>
                <li>
                  <button
                    onClick={logout} // Çıkış yap
                    className="hover:text-red-500"
                  >
                    Çıkış Yap
                  </button>
                </li>
              </>
            ) : ( // Kullanıcı giriş yapmadıysa
              <>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};


export default App;
